import { SearchResponse } from '@algolia/client-search';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Accommodation, HomeAlgolia, HousingProvider } from '@models/housing/accomodation';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { SearchParamsObject } from 'algoliasearch';
import { Observable, from, map, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WizbiiHomeWebservice {
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);
  readonly #baseApiUrl = environment.api.accommodation;
  readonly #http = inject(HttpClient);

  #getClient() {
    return this.#algoliaClientProvider.getClient(environment.algolia.clientName)!;
  }

  searchHomes(
    query: string | null,
    params: Pick<
      SearchParamsObject,
      'page' | 'hitsPerPage' | 'facetFilters' | 'aroundLatLng' | 'aroundRadius' | 'filters' | 'optionalFilters'
    >
  ): Observable<SearchResponse<HomeAlgolia>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForHits<HomeAlgolia>({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiHousing,
                query: query ?? '',
                ...params,
              },
            ],
          })
        )
      ),
      map((response) => response.results[0])
    );
  }

  #getRegexpGroup<T extends string>(urlWithPartnerIdMatchers: RegExpMatchArray | null, key: T): string | undefined {
    return urlWithPartnerIdMatchers?.groups?.[key];
  }

  #addKolivingTracking(providers: HousingProvider[], partnerId: string): HousingProvider[] {
    return providers.map((provider) => {
      if (provider.name === 'koliving') {
        const urlWithPartnerIdMatchers = provider.url.match(/(?<baseUrl>.*)\?partnerid=(?<partnerSource>[^&]*)/);
        const partnerSource = this.#getRegexpGroup(urlWithPartnerIdMatchers, 'partnerSource') ?? 'wizbii';
        const baseUrl = this.#getRegexpGroup(urlWithPartnerIdMatchers, 'baseUrl');
        const url = baseUrl ? `${baseUrl}?partnerid=${partnerId}&partnersource=${partnerSource}` : provider.url;
        return { ...provider, url };
      }
      return provider;
    });
  }

  getAccommodation(id: string, partnerId?: string): Observable<Accommodation> {
    return this.#http.get<Accommodation>(`${this.#baseApiUrl}/v1/accommodation/${id}`).pipe(
      map((accommodation) => ({
        ...accommodation,
        providers: partnerId ? this.#addKolivingTracking(accommodation.providers, partnerId) : accommodation.providers,
      }))
    );
  }
}
